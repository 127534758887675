import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PUBLIC_API_HOST } from '../constants';

export function getCookie(name) {
  var dc = document.cookie;
  var prefix = name + '=';
  var begin = dc.indexOf('; ' + prefix);
  if (begin === -1) {
    begin = dc.indexOf(prefix);
    if (begin !== 0) return null;
  } else {
    begin += 2;
    var end = document.cookie.indexOf(';', begin);
    if (end === -1) {
      end = dc.length;
    }
  }

  return decodeURI(dc.substring(begin + prefix.length, end));
}

export function setCookie(name, value) {
  document.cookie = name + '=' + value + '; Path=/;';
}
export function deleteCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
export const useAuth = () => {
  const [isLogin, setLogin] = useState(!!getCookie('llanera_token'));
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (isLogin) {
      axios.get(`${PUBLIC_API_HOST}api/usuarios/one.php?id=me`).then((res) => {
        setUser(res.data);
      });
    }
  }, []);
  const context = {
    isLogin: isLogin,
    user,
    logout: () => {
      deleteCookie('llanera_token');
      setLogin(false);
    },
    login: (_user) => {
      setLogin(true);
      setUser(_user);
    }
  };

  return context;
};

export function RequireAuth({ children }) {
  const { isLogin ,user} = useContext(AuthContext);
  const location = useLocation();

  return isLogin === true && user? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export function RequireAuthAdmin({ children }) {
  const { isLogin, user } = useContext(AuthContext);
  const location = useLocation();

  return isLogin === true && user?.rol === 'admin' ? (
    children
  ) : isLogin === true ? (
    <Navigate to="/" replace />
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export const AuthContext = createContext();
