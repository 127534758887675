import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useState } from 'react';
import { PUBLIC_API_HOST } from '../core/constants';
import CreateSite from './site/create';
import SearchSite from './site/search';
const Search = () => {
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const search = (form) => {
    axios
      .get(PUBLIC_API_HOST + 'api/concejos/buscador.php', { params: form })
      .then((res) => {
        setSites(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };
  return (
    <Container>
      <Paper>
        <h1>Buscador</h1>
        <Container>
          <section>
            <CreateSite
              callback={search}
              btnText="Buscar"
              title="Buscador"
              hiddeCoor
              hiddeListLink
            />
          </section>

          <section>
            {sites?.length ? (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Abrir</TableCell>
                        <TableCell>Nombre </TableCell>
                        <TableCell>Lugar</TableCell>
                        <TableCell>Poblacion</TableCell>
                        <TableCell>Codigo_postal</TableCell>
                        <TableCell>Numero</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sites.map((site) => (
                        <TableRow key={site.id}>
                          <TableCell>
                            <a
                              target="_blank"
                              href={`https://www.google.com/maps/search/?api=1&query=${site.coordenadax}`}
                              rel="noreferrer"
                            >
                              Abrir en google maps
                            </a>
                          </TableCell>
                          <TableCell>{site.nombre}</TableCell>
                          <TableCell>{site.lugar}</TableCell>
                          <TableCell>{site.poblacion}</TableCell>
                          <TableCell>{site.codigo_postal}</TableCell>
                          <TableCell>{site.numero}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <></>
            )}
          </section>
        </Container>
      </Paper>
    </Container>
  );
};

export default Search;
