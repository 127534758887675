import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import DeleteIcon from '@mui/icons-material/Delete';

import Container from '@mui/material/Container';
import { PUBLIC_API_HOST } from '../../core/constants';
import { Alert } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ListSite = () => {
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState({
    severity: '',
    show: false,
    message: '',
  });
  const [openModal, setOpenModal] = useState({ show: false, id: null });

  const deleteSite = (id) => {
    axios
      .delete(`${PUBLIC_API_HOST}api/concejos/eliminar.php?id=${id}`)
      .then((res) => {
        setShowToast({
          severity: 'success',
          show: true,
          message: 'Sitio eliminado correctamente',
        });
        setOpenModal({ show: false, id: null });
        setSites(sites.filter((site) => site.id !== id));
        setTimeout(() => {
          setShowToast({ show: false, message: '' });
        }, 3000);
      })
      .catch((err) => {
        setOpenModal({ show: false, id: null });
        setShowToast({
          severity: 'error',
          show: true,
          message: 'Ha ocurrido un error al eliminar el sitio',
        });
        setTimeout(() => {
          setShowToast({ show: false, message: '' });
        }, 3000);
      });
  };
  useEffect(() => {
    axios
      .get(PUBLIC_API_HOST + 'api/concejos/list.php')
      .then((res) => {
        setSites(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <Modal
        open={openModal.show}
        onClose={() => setOpenModal({ show: false, id: null })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Desea eliminar la calle {openModal?.id}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button onClick={() => deleteSite(openModal.id)}> SI </Button>
            <Button onClick={() => setOpenModal({ show: false, id: null })}>
              {' '}
              No{' '}
            </Button>
          </Typography>
        </Box>
      </Modal>

      {showToast.show ? (
        <Alert severity={showToast.severity}>{showToast.message}</Alert>
      ) : (
        <></>
      )}
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          {error ? (
            <>{error}</>
          ) : (
            <>
              <h1>Sitios</h1>
              <Link to={'/create-site'}>
                <Button> Añadir</Button>
              </Link>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Nombre </TableCell>
                      <TableCell>Lugar</TableCell>
                      <TableCell>Poblacion</TableCell>
                      <TableCell>Codigo_postal</TableCell>
                      <TableCell>Numero</TableCell>
                      <TableCell>Coordenadas</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sites.map((site) => (
                      <TableRow key={site.id}>
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            onClick={() =>
                              setOpenModal({ show: true, id: site.id })
                            }
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Link to={`/edit-site/${site.id}`}>{site.id}</Link>
                        </TableCell>
                        <TableCell>{site.nombre}</TableCell>
                        <TableCell>{site.lugar}</TableCell>
                        <TableCell>{site.poblacion}</TableCell>
                        <TableCell>{site.codigo_postal}</TableCell>
                        <TableCell>{site.numero}</TableCell>
                        <TableCell>
                        <a
                              target="_blank"
                              href={`https://www.google.com/maps/search/?api=1&query=${site.coordenadax}`}
                              rel="noreferrer"
                            >
                              Abrir en google maps
                            </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ListSite;
