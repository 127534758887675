import Navbar from './core/navbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; // ES6
import 'normalize.css';

import MenuAppBar from './core/navbar/menuAppBar';
import {Footer} from './core/footer/footer';
import { theme } from './theme/default';

function App() {
  return (
    <ThemeProvider theme={theme}>
        <div className="App">
          {/* <Navbar /> */}
          <MenuAppBar />
          <Outlet />
          <Footer />
        </div>
    </ThemeProvider>
  );
}

export default App;
