import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik, Field } from 'formik';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import { Box } from '@mui/system';
import { PUBLIC_API_HOST } from '../../core/constants';

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const SearchSite = ({ callback, btnText = 'Guardar' }) => {
  const [places, setPlaces] = useState([]);
  const [poblaciones, setPoblaciones] = useState([]);
  const [concejos, setConcejos] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const formik = useFormik({
    initialValues: {
      nombre: 'Llanera',
      lugar: '',
      poblacion: '',
      codigo_postal: '',
      numero: ''
    },
    onSubmit: (values) => {
      const result = values;

      callback(result);
    }
  });
  const nameDebounce = useDebounce(formik.values?.nombre, 500);
  const placeDebounce = useDebounce(formik.values?.lugar, 500);
  const poblacionDebounce = useDebounce(formik.values?.poblacion, 500);
  // Effect for API call
 useEffect(()=>{
  axios
  .get(
    `${PUBLIC_API_HOST}api/concejos/concejos.php`
  )
  .then((res) => {
    setConcejos(res.data);
    setPlaces([]);
    setIsSearching(false);
  })
  .catch((err) => {
    alert(err);
    setIsSearching(false);
  });
 },[])

  useEffect(
    () => {
      formik.setFieldValue('poblacion', '');
      if (nameDebounce) {
        setIsSearching(true);
        if (nameDebounce.length > 2) {
          axios
            .get(
              `${PUBLIC_API_HOST}api/concejos/lugares.php?concejo=${nameDebounce}`
            )
            .then((res) => {
              setPlaces(res.data || []);
              setIsSearching(false);
            })
            .catch((err) => {
              alert(err);
              setIsSearching(false);
            });
        } else {
          setPlaces([]);
          setIsSearching(false);
        }
      } else {
        setPlaces([]);
        setIsSearching(false);
      }
    },
    [nameDebounce] // Only call effect if debounced search term changes
  );

  useEffect(
    () => {
      if (placeDebounce) {
        setIsSearching(true);
        if (placeDebounce.length > 2) {
          axios
            .get(
              `${PUBLIC_API_HOST}api/concejos/poblaciones.php?concejo=${nameDebounce}&lugar=${placeDebounce}`
            )
            .then((res) => {
              setPoblaciones(res.data || []);
              setIsSearching(false);
            })
            .catch((err) => {
              alert(err);
              setIsSearching(false);
            });
        } else {
          setPoblaciones([]);
          setIsSearching(false);
        }
      } else {
        setPoblaciones([]);
        setIsSearching(false);
      }
    },
    [placeDebounce,nameDebounce] // Only call effect if debounced search term changes
  );

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Box
          component="form"
          xl={{
            '& .MuiTextField-root': { m: 1, width: '25ch' }
          }}
          noValidate
          autoComplete="off"
        >
         
         <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Concejo</InputLabel>
            <Select
              value={formik.values['nombre']}
              onChange={(e) => {
                formik.setFieldValue('nombre', e.target.value);
              }}
              id="nombre"
              margin="dense"
              label="Lugar"
              fullWidth
              name="nombre"
              type="text"
            >
              {concejos.map((place, i) => (
                <MenuItem key={place.nombre + i} value={place.nombre}>
                  {place.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Lugar</InputLabel>
            <Select
              value={formik.values['lugar']}
              onChange={(e) => {
                formik.setFieldValue('lugar', e.target.value);
              }}
              id="lugar"
              margin="dense"
              label="Lugar"
              fullWidth
              name="lugar"
              type="text"
            >
              {places.map((place, i) => (
                <MenuItem key={place.lugar + i} value={place.lugar}>
                  {place.lugar}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Poblacion</InputLabel>
            <Select
              value={formik.values['poblacion']}
              onChange={(e) => {
                formik.setFieldValue('poblacion', e.target.value);
              }}
              id="poblacion"
              margin="dense"
              label="Lugar"
              fullWidth
              name="poblacion"
              type="text"
            >
              {poblaciones.map((place, i) => (
                <MenuItem key={place.poblacion} value={place.poblacion}>
                  {place.poblacion}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="numero"
            margin="dense"
            label="Número"
            fullWidth
            name="numero"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.numero}
          />

          <TextField
            id="codigo_postal"
            margin="dense"
            label="Codigo Postal"
            fullWidth
            name="codigo_postal"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.codigo_postal}
          />
        </Box>
        <Button type="submit">{btnText}</Button>
      </form>
    </Container>
  );
};

export default SearchSite;
