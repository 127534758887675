import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik, Field } from 'formik';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { PUBLIC_API_HOST } from '../core/constants';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { AuthContext } from '../core/auth/context';

const Login = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const { state } = useLocation();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      const result = values;

      axios
        .post(`${PUBLIC_API_HOST}api/auth/login.php`, result)
        .then((res) => {
          auth.login(res.data);
          navigate(state?.path || '/list-sites');
        })
        .catch((err) => {
          if (err?.response?.data?.error) {
            alert(err.response.data.error);
          } else {
            alert('Error');
          }
        });
    }
  });

  return (
    <Container>
      <h1>Iniciar sesión</h1>

      <form onSubmit={formik.handleSubmit}>
        <Box
          component="form"
          xl={{
            '& .MuiTextField-root': { m: 1, width: '25ch' }
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="email"
            margin="dense"
            label="Concejo"
            fullWidth
            name="email"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.email}
          />

          <TextField
            id="password"
            margin="dense"
            label="Contraseña"
            fullWidth
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </Box>
        <Button type="submit">{'Iniciar sesión'}</Button>
      </form>
    </Container>
  );
};

export default Login;
