/* eslint-disable react/jsx-no-target-blank */
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';

import Container from '@mui/material/Container';

import Grid from '@mui/material/Grid';
const footerItem = {
  color: 'secondary',
  margin: '0.5rem',
  textAlign: 'center'
};
export const Footer = () => {
  return (
    <>
      <Divider light />
      <footer style={{ marginTop: '1em' }}>
        <Container>
          <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
              <Typography
                sx={{ color: 'secondary.main', textAlign: 'center' }}
                gutterBottom
                color="secondary"
                variant="p"
                component="div"
              >
                Contacto <a href='mailto:llanera02@gmail.com'>llanera02@gmail.com</a>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                sx={{ color: 'secondary.main', textAlign: 'center' }}
                gutterBottom
                color="secondary"
                variant="p"
                component="div"
              >
                Hecho por <a href="https://github.com/kmikodev">Jaime Cardona Villegas</a>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                sx={{ color: 'secondary.main', textAlign: 'center' }}
                gutterBottom
                variant="p"
                component="div"
              >
                En colaboración con
              </Typography>

              <center>
                <a
                  href="http://miampa.com/"
                  title="miampa.com"
                  rel="referrer"
                  target="_blank"
                >
                  <img
                    style={{ margin: '0 auto', width: '250px' }}
                    src="https://miampa.com/wp-content/uploads/2017/09/cropped-cropped-Logomiampa400-1.png"
                    alt="Miampa | Software de gestión para AMPAS APYMAS"
                  />
                </a>
              </center>
            </Grid>

            <Grid item xs={12} md={3}>
              <center>
                <a
                  href="http://hexamob.com/"
                  rel="referrer"
                  title="hexamob.com"
                  target="_blank"
                >
                  <img
                    src="https://rankgea.com/img/hexamob-logo.png"
                    alt="Hexamob | How to root your Android phone, cellular or tablet with rooting methods compilation. Tutorials, videos and more."
                  />
                </a>
              </center>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};
