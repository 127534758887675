import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { createContext, useState } from 'react';

import Home from './pages/home';
import CreateSite from './pages/site/create';
import ListSite from './pages/site/list';

import CreateUser from './pages/user/create';
import ListUser from './pages/user/list';

import Search from './pages/search';
import App from './App';
import Login from './pages/Login';
import { AuthContext, RequireAuth, RequireAuthAdmin, useAuth } from './core/auth/context';

export default function Entry() {
  const context = useAuth();
  return (
    <BrowserRouter>
      <AuthContext.Provider value={context}>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path={'/buscador'} element={<Search />} />

            <Route
              path="/dashboard"
              element={<RequireAuth>privado</RequireAuth>}
            />
            <Route
              path="/settings"
              element={<RequireAuth>privado</RequireAuth>}
            />
            <Route path="/login" element={<Login />} />

            <Route
              path="/edit-site/:id"
              element={
                <RequireAuth>
                  <CreateSite />{' '}
                </RequireAuth>
              }
            />
            <Route
              path="/create-site"
              element={
                <RequireAuth>
                  <CreateSite />{' '}
                </RequireAuth>
              }
            />
            <Route
              path="/list-sites"
              element={
                <RequireAuth>
                  <ListSite />{' '}
                </RequireAuth>
              }
            />

            <Route
              path="/edit-user/:id"
              element={
                <RequireAuth>
                  <CreateUser />{' '}
                </RequireAuth>
              }
            />
            <Route
              path="/create-user"
              element={
                <RequireAuthAdmin>
                  <CreateUser />{' '}
                </RequireAuthAdmin>
              }
            />
            <Route
              path="/list-users"
              element={
                <RequireAuthAdmin>
                  <ListUser />{' '}
                </RequireAuthAdmin>
              }
            />
          </Route>
        </Routes>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}
