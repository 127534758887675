import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Parallax, Background } from 'react-parallax';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useState } from 'react';
import { PUBLIC_API_HOST } from '../core/constants';
import CreateSite from './site/create';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import { Navigate } from 'react-router-dom';

const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center'
};
const insideStyles = {
  padding: 20,
  position: 'absolute',
  fontSize: 'calc(20px + 2vmin)',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)'
};

const image1 =
  'https://images.unsplash.com/photo-1498092651296-641e88c3b057?auto=format&fit=crop&w=1778&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D';
const image2 =
  'https://img00.deviantart.net/2bd0/i/2009/276/c/9/magic_forrest_wallpaper_by_goergen.jpg';
const image3 =
  'https://brightcove04pmdo-a.akamaihd.net/5104226627001/5104226627001_5297440765001_5280261645001-vs.jpg?pubId=5104226627001&videoId=5280261645001';
const image4 =
  'https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/empire-state-building-black-and-white-square-format-john-farnan.jpg';
const Hello = ({ name }) => <h1>Hello {name}!</h1>;

const Home = () => {
  const [redirect, setRedirect] = useState(false);

  return (
    <div maxWidth="xl">
      {redirect && <Navigate to="/buscador" />}
      <div style={styles}>
        <Parallax bgImage="/llanera_2.jpg" strength={300}>
          <div style={{ height: 800 }}>
            <div style={insideStyles} item xs={12}>
              <Button
                onClick={() => setRedirect(true)}
                variant="contained"
                styles={{ margin: '400px' }}
                color="primary"
              >
                Accede a nuestro buscador
              </Button>
            </div>
          </div>
        </Parallax>
        **
        <Container>
       
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item mdUp={4}>
                <Paper>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                      component="img"
                      height="200"
                      image="/llanera_card_1.jpg"
                      alt="Llanera"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Llanera
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Con nuestra ayuda, te será más fácil encontrar el lugar dónde quieres ir.
                      </Typography>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item mdUp={4}>
                <Paper>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                      component="img"
                      height="200"
                      image="/maps.png"
                      alt="Llanera"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        No más perdidas
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Busca el lugar de Llanera dónde quieras ir.
                      </Typography>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item mdUp={4}>
                <Paper>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                      component="img"
                      height="200"
                      image="/buscador.png"
                      alt="Llanera"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Accede a nuestro buscador
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                       Rellena los datos y llegarás a tu destino.
                      </Typography>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => setRedirect(true)}
                  color="primary"
                >
                  Accede a nuestro buscador
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <h2>{'\u2728'}</h2>
      </div>
    </div>
  );
};

export default Home;
