import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import { Link, useNavigate } from 'react-router-dom';

import { AuthContext } from '../auth/context';

export default function MenuAppBar() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const auth = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setOpen(!open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    auth.logout();
    handleClose();
  };

  const goToProfile = () => {
    navigate('/edit-user/me');
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Link to="/">
              <img
                src="/logo.png"
                width="200px"
                alt={'DeLlanera'}
                style={{ margin: '10px', width: '150px' }}
                loading="lazy"
              />
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <Link to="/">
              <img
                src="/logo.png"
                width="200px"
                alt={'DeLlanera'}
                style={{ margin: '10px', width: '150px' }}
                loading="lazy"
              />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Button
                onClick={() => setOpen(false)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Inicio
              </Button>
            </Link>
            <Link to="/buscador" style={{ textDecoration: 'none' }}>
              <Button
                onClick={() => setOpen(false)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Buscador
              </Button>
            </Link>
            {auth.isLogin ? (
              <>
                <Link to="/list-sites" style={{ textDecoration: 'none' }}>
                  <Button
                    onClick={() => setOpen(false)}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Sitios
                  </Button>
                </Link>
                <Link to="/list-users" style={{ textDecoration: 'none' }}>
                  <Button
                    onClick={() => setOpen(false)}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Usuarios
                  </Button>
                </Link>
              </>
            ) : (
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <Button
                  onClick={() => setOpen(false)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Iniciar sesión
                </Button>
              </Link>
            )}
          </Box>
          {auth.isLogin && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                {auth.user?.name}
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={goToProfile}>Perfil</MenuItem>
                <MenuItem onClick={logout}>Cerrar session</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpen(false)}
          onKeyDown={() => setOpen(false)}
        >
          <List>
            <Link
              to="/"
              style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
            >
              <ListItem button>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={'Home'} />
              </ListItem>
            </Link>
            <Link
              to="/buscador"
              style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
            >
              <ListItem button>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary={'Buscador'} />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            {auth.isLogin ? (
              <>
                <Link
                  to="/list-sites"
                  style={{
                    textDecoration: 'none',
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Sitios'} />
                  </ListItem>
                </Link>

                {auth.user?.rol === 'admin' && (
                  <Link
                    to="/list-users"
                    style={{
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.87)'
                    }}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Usuarios'} />
                    </ListItem>
                  </Link>
                )}
              </>
            ) : (
              <Link
                to="/login"
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Iniciar sesión'} />
                </ListItem>
              </Link>
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
}
