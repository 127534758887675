import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik, Field } from 'formik';

import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useParams, Navigate, Link } from 'react-router-dom';
import { PUBLIC_API_HOST } from '../../core/constants';
import { FormControl, InputLabel, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';

const CreateUser = ({
  callback,
  title = 'Crear',
  btnText = 'Guardar',
  hiddeCoor = false,
  hiddeListLink = false
}) => {
  const params = useParams();
  const [redirect, setRedirect] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      rol: ''
    },
    onSubmit: (values) => {
      const result = values;
      if (callback) {
        callback(result);
      } else {
        const promise = params.id
          ? axios.put(
              `${PUBLIC_API_HOST}api/usuarios/editar.php?id=${params.id}`,
              result
            )
          : axios.post(`${PUBLIC_API_HOST}api/usuarios/crear.php`, result);

        promise
          .then((res) => {
            setRedirect(true);
          })
          .catch((err) => {
            if (err?.response?.data?.error) {
              alert(err.response.data.error);
            } else {
              alert('Error');
            }
          });
      }
    }
  });
  useEffect(() => {
    if (params.id) {
      axios
        .get(`${PUBLIC_API_HOST}api/usuarios/one.php?id=${params.id}`)
        .then((res) => {
          formik.setValues(res.data);
        })
        .catch((err) => {
          alert(err);
        });
    }
  }, [params]);

  return (
    <Container>
      {redirect ? (
        <>
          <Navigate to={`/list-users`} />
        </>
      ) : (
        <></>
      )}
      <h1>{params.id ? `Editar` : title} usuario</h1>

      {hiddeListLink ? (
        <></>
      ) : (
        <h4>
          <Link to={`/list-users`}>Ir al listado de usuarios </Link>
        </h4>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box
          component="form"
          xl={{
            '& .MuiTextField-root': { m: 1, width: '25ch' }
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="name"
            margin="dense"
            label="Nombre"
            fullWidth
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <TextField
            id="email"
            margin="dense"
            label="Email"
            fullWidth
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />

          <TextField
            id="password"
            margin="dense"
            label="Contraseña"
            fullWidth
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Rol</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formik.values['rol']}
              onChange={(e) => {
                formik.setFieldValue('rol', e.target.value);
              }}
              name="rol"
              label="Rol"
            >
              <MenuItem value={'admin'}>Admin</MenuItem>
              <MenuItem value={'user'}>Usuario</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button type="submit">{btnText}</Button>
      </form>
    </Container>
  );
};

export default CreateUser;
