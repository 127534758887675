import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik, Field } from 'formik';

import Container from '@mui/material/Container';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useParams, Navigate, Link } from 'react-router-dom';
import { PUBLIC_API_HOST } from '../../core/constants';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';

const CreateSite = ({
  callback,
  title = 'Crear',
  btnText = 'Guardar',
  hiddeCoor = false,
  hiddeListLink = false,
}) => {
  const params = useParams();
  const [redirect, setRedirect] = useState(false);
  const formik = useFormik({
    initialValues: {
      nombre: '',
      lugar: '',
      poblacion: '',
      codigo_postal: '',
      numero: '',
      coordenadax: '',
    },
    onSubmit: (values) => {
      const result = values;
      if (callback) {
        callback(result);
      } else {
        const promise = params.id
          ? axios.put(
              `${PUBLIC_API_HOST}api/concejos/editar.php?id=${params.id}`,
              result
            )
          : axios.post(`${PUBLIC_API_HOST}api/concejos/crear.php`, result);

        promise
          .then((res) => {
            setRedirect(true);
          })
          .catch((err) => {
            if (err?.response?.data?.error) {
              alert(err.response.data.error);
            } else {
              alert('Error');
            }
          });
      }
    },
  });
  useEffect(() => {
    if (params.id) {
      axios
        .get(`${PUBLIC_API_HOST}api/concejos/one.php?id=${params.id}`)
        .then((res) => {
          formik.setValues(res.data);
        })
        .catch((err) => {
          alert(err);
        });
    }
  }, [params]);

  return (
    <Container>
      {redirect ? (
        <>
          <Navigate to={`/list-sites`} />
        </>
      ) : (
        <></>
      )}
      <h1>{params.id ? `Editar` : title} sitio</h1>

      {hiddeListLink ? (
        <></>
      ) : (
        <h4>
          <Link to={`/list-sites`}>Ir al listado de sitios </Link>
        </h4>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box
          component="form"
          xl={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="nombre"
            margin="dense"
            label="Concejo"
            fullWidth
            name="nombre"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.nombre}
          />

          <TextField
            id="lugar"
            margin="dense"
            label="Lugar"
            fullWidth
            name="lugar"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.lugar}
          />

          <TextField
            id="poblacion"
            margin="dense"
            label="Población"
            fullWidth
            name="poblacion"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.poblacion}
          />

          <TextField
            id="numero"
            margin="dense"
            label="Número"
            fullWidth
            name="numero"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.numero}
          />


          <TextField
            id="codigo_postal"
            margin="dense"
            label="Codigo Postal"
            fullWidth
            name="codigo_postal"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.codigo_postal}
          />
          {hiddeCoor ? (
            <></>
          ) : (
            <TextField
              id="coordenadax"
              margin="dense"
              label="Coordenadas"
              fullWidth
              name="coordenadax"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.coordenadax}
            />
          )}
        </Box>
        <Button type="submit">{btnText}</Button>
      </form>
    </Container>
  );
};

export default CreateSite;
